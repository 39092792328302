// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  message: 'hello dev'
};

// export const SERVER_URL = 'https://api.foodbeacon.com/';//Prod
// export const UserPoolId = 'us-east-1_hdCrETDbo';
// export const ClientId = '1ui8vaeiujumt0uoc437skg9i';
// export const ApiKey = '';
// export const redirectSignIn = "https://foodbeacon.com/";
// export const redirectSignOut =  "https://foodbeacon.com/";
// export const s3bucket =  "https://s3.amazonaws.com/uat.foodbeacon.io";


// export const SERVER_URL = 'https://dev.api.foodbeacon.io/';//Dev
// export const UserPoolId = '';
// export const ClientId = '';
// export const ApiKey = '';
// export const redirectSignIn = "http://localhost:4200";
// export const redirectSignOut =  "http://localhost:4200";
// export const SERVER_URL = 'https://uat.api.foodbeacon.com/';//Uat
// export const UserPoolId = 'us-east-1_X2CRrUpFN';
// export const ClientId = '5uoe9rh4tu97sundivr912h4ik';
// export const s3bucket =  "https://s3.amazonaws.com/uat.foodbeacon.io";


export const SERVER_URL = 'https://uat.api.foodbeacon.com/';//Uat
export const UserPoolId = 'us-east-1_AAjIA2g6T';
export const ClientId = '7tdju44m6vone983td0f0hrlqf';
export const ApiKey = '';
export const redirectSignIn = "https://uat.foodbeacon.com/";
export const redirectSignOut =  "https://uat.foodbeacon.com/";
export const s3bucket =  "https://s3.amazonaws.com/uat.foodbeacon.io";



/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
