import { Component, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Platform, NavController, MenuController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { ApiService } from './services/api.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { AdminDropdownComponent } from './popover/admin-dropdown/admin-dropdown.component';
//import { Events } from '@ionic/angular';
declare var $: any;
declare var Nav: any;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  public selectedIndex = 0;
  public view = 'managestores';
  public appPages = [
    {
      title: 'Manage Stores',
      url: 'managestores',
      icon: 'assets/imgs/dashboard.png',
      icon_active: 'assets/imgs/dashboard-active.png'
    },

    {
      title: 'Accounts & Settings',
      url: '/folder/Archived',
      icon: 'assets/imgs/settings.png',
      icon_active: 'assets/imgs/settings-active.png'
    },

    {
      title: 'Logout',
      url: 'login',
      icon: 'assets/imgs/logout.png',
      icon_active: 'assets/imgs/logout-active.png'
    }
  ];
  public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private router: Router,
    private zone: NgZone,
    private menu: MenuController,
    private service:ApiService
  ) {
    this.initializeApp();
    // events.subscribe('menu:update', (user, time) => {
    //  this.view=user;
    // });
  }

  initializeApp() {
    this.menu.enable(false);
    this.platform.ready().then(() => {

      let instance = this;
    
      // window.onpopstate = function () {

      //   instance.zone.run(() => {
          
      //     if (localStorage.getItem("name") != null && localStorage.getItem("name") != '') {

      //     } else {

      //       setTimeout(() => {
      //         instance.router.navigate(['/login']);
      //       }, 200);

      //       // instance.gotologin()
      //     }
      //   });


      // }; history.pushState({}, '');
      if (this.platform.is('ios') || this.platform.is('android')) {
        this.statusBar.styleDefault();
        this.splashScreen.hide();
      }
      this.menu.enable(false);
      if (this.platform.is('ios') || this.platform.is('android')) {
        if (localStorage.getItem("name") != null && localStorage.getItem("name") != '') {
          this.menu.enable(false);
          this.router.navigate(['/dashboard']);
        } else {
          this.menu.enable(false);
          this.router.navigate(['/login']);
        }
      }
    });
  }

  gotologin() {
    this.menu.enable(false);
    this.router.navigate(['/login']);
  }

  ngOnInit() {

  }

  //Side menu click event

  open(page) {

    this.view = page;
    this.service.menu_sel=this.view;
    if (this.view == 'login') {
      this.view = 'managestores';
      this.router.navigateByUrl('login')
    } else {
      this.router.navigateByUrl(page)
    }
  }

  dashboard() {
    this.menu.enable(false);
    this.router.navigate(['/dashboard']);
  }

}
