import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { ToastController } from '@ionic/angular';
import { Network } from '@ionic-native/network/ngx';
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import { CognitoAuth } from 'amazon-cognito-auth-js';
//import { environment, apiUrl } from '../../environments/environment';
import { environment, SERVER_URL, UserPoolId, ClientId, ApiKey } from '../../environments/environment';
import { Router } from '@angular/router';
import { FixDateOffsetService } from './fix-date-offset.service';
import { inject } from '@angular/core';

var navigator;

interface ItemsResponse { }

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  user_email: any = '';
  user_id: any = '';
  username: any = '';
  loginemail: any = '';
  loginpassword: any = '';
  usertype: any = '';
  store_id: any = '';
  apiUrl: any = '';
  listofusers;
  UserPoolId = UserPoolId;
  ClientId = ClientId;
  ApiKey = ApiKey;
  menu_sel = 'managestores';
  slider_enabled: boolean;
  token: any = '';
  fixDateOffsetService = inject(FixDateOffsetService)

  constructor(public http: HttpClient, private toast: ToastController, public network: Network, private router: Router) {

    //(SERVER_URL)
    //(this.UserPoolId);
    //(this.ClientId);
  }
  //Rest API method
  get_data(action): Observable<ItemsResponse> {
    this.token = localStorage.getItem("auth_token");

    if (action != '') {
      const httpOptions = {
        headers: new HttpHeaders()
          .set('x-api-key', ApiKey)
          .set('Authorization', 'Bearer ' + this.token)
      };
      return this.http.get(SERVER_URL + action, httpOptions)
        .pipe(map((response: Response) => {

          return response;
        }))
    } else {
      return this.http.get('http://localhost:1111/temperature')
        .pipe(map((response: Response) => {

          return response;
        }))
    }
  }
  //Rest API method
  getData(servletPath: string, sensor_id: string, start_date: string, end_date: string, local_timezone: string): Observable<ItemsResponse> {
    this.token = localStorage.getItem("auth_token");
    let encoded_timezone = encodeURIComponent(local_timezone);
    let pathParams =
      "sensor_id=" + sensor_id + "&start_date=" + start_date +
      "&end_date=" + end_date + "&local_timezone=" + encoded_timezone;
    let request_url = SERVER_URL + servletPath + pathParams;
    const httpOptions = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + this.token)
    };
    return this.http.get(request_url, httpOptions)
      .pipe(map((response: Response) => {

        return response;
      }))

  }


  get_local_data(): Observable<ItemsResponse> {
    this.token = localStorage.getItem("auth_token");
    return this.http.get('http://localhost:1111/store_info')
      .pipe(map((response: Response) => {
        return response;
      }))
  }

  apicall_re_inviteuser(action): Observable<ItemsResponse> {
    this.token = localStorage.getItem("auth_token");
    const httpOptions = {
      headers: new HttpHeaders().set(
        'x-api-key', ApiKey
      )
        .set('Authorization', 'Bearer ' + this.token)
    };
    const body = {};

    return this.http.post<any>(SERVER_URL + action, body, httpOptions)
      .pipe(map((response: Response) => {
        return response;
      }))
  }

  delapi(action): Observable<ItemsResponse> {
    this.token = localStorage.getItem("auth_token");
    const httpOptions = {
      headers: new HttpHeaders().set(
        'x-api-key', ApiKey
      )
        .set('Authorization', 'Bearer ' + this.token)
    };
    return this.http.delete(action, httpOptions)
      .pipe(map((response: Response) => {
        return response;
      }))
  }

  localapi(): Observable<ItemsResponse> {
    return this.http.get('http://localhost:1111/storelist')
      .pipe(map((response: Response) => {
        return response;
      }))
  }

  localapipost(prod): Observable<ItemsResponse> {
    return this.http.post('http://localhost:1111/storelist', prod)
      .pipe(map((response: Response) => {
        return response;
      }))
  }

  localapipostupdate(prod, id): Observable<ItemsResponse> {
    return this.http.put('http://localhost:1111/storelist/' + id, prod)
      .pipe(map((response: Response) => {
        return response;
      }))
  }


  //delete api call
  deleteapi(action): Observable<ItemsResponse> {
    this.token = localStorage.getItem("auth_token");
    const httpOptions = {
      headers: new HttpHeaders().set(
        'x-api-key', ApiKey
      )
        .set('Authorization', 'Bearer ' + this.token)
    };
    return this.http.delete(SERVER_URL + action, httpOptions)
      .pipe(map((response: Response) => {
        return response;
      }))
  }

  //get api call
  apicall(action): Observable<ItemsResponse> {
    this.token = localStorage.getItem("auth_token");
    const httpOptions = {
      headers: new HttpHeaders().set(
        'x-api-key', ApiKey
      )
        .set('Authorization', 'Bearer ' + this.token)
    };
    return this.http.get(SERVER_URL + action, httpOptions)
      .pipe(map((response: Response) => {

        const stores = JSON.parse(response.body.toString())
        stores.forEach((store) => {
          // Adjust the times of the holidays pulled from the database to match current offset
          store['holiday_date'] = this.fixDateOffsetService.fixDateOffset((store['holiday_date']))
        });
        
        // Adjust the the response
        const modifiedResponse = {
          ...response,
          body: JSON.stringify(stores)
        }
        return modifiedResponse
      }))
  }

  //post api call
  apicallsignup(action): Observable<ItemsResponse> {
    this.token = localStorage.getItem("auth_token");
    const options = {
      headers: new HttpHeaders().set(
        'x-api-key', ApiKey
      )
        .set('Authorization', 'Bearer ' + this.token)
    };
    const body = {};
    return this.http.post<any>(SERVER_URL + action, body, options)
      .pipe(map((response: Response) => {

        return response;
      }))
  }

  //update api call
  apicallupdate(action): Observable<ItemsResponse> {
    this.token = localStorage.getItem("auth_token");
    const httpOptions = {
      headers: new HttpHeaders().set(
        'x-api-key', ApiKey
      )
        .set('Authorization', 'Bearer ' + this.token)
    };
    const body = {};
    return this.http.put(SERVER_URL + action, body, httpOptions)
      .pipe(map((response: Response) => {
        return response;
      }))
  }
  apicall_inviteuser(action): Observable<ItemsResponse> {
    this.token = localStorage.getItem("auth_token");
    const httpOptions = {
      headers: new HttpHeaders().set(
        'x-api-key', ApiKey
      )
        .set('Authorization', 'Bearer ' + this.token)
    };
    const body = {};
    return this.http.post<any>(SERVER_URL + action, body, httpOptions)
      .pipe(map((response: Response) => {
        return response;
      }))
  }

  apicall_edituser(action): Observable<ItemsResponse> {
    this.token = localStorage.getItem("auth_token");
    const httpOptions = {
      headers: new HttpHeaders().set(
        'x-api-key', ApiKey
      )
        .set('Authorization', 'Bearer ' + this.token)
    };
    const body = {};
    return this.http.post<any>(SERVER_URL + action, body, httpOptions)
      .pipe(map((response: Response) => {
        return response;
      }))
  }

  //delete api call
  deleteuser(action): Observable<ItemsResponse> {
    this.token = localStorage.getItem("auth_token");
    const httpOptions = {
      headers: new HttpHeaders().set(
        'x-api-key', ApiKey
      )
        .set('Authorization', 'Bearer ' + this.token)
    };
    return this.http.delete(SERVER_URL + action, httpOptions)
      .pipe(map((response: Response) => {
        return response;
      }))
  }

  checkconnection() {
    if (this.network.type === 'wifi') {

      return true;
    } else {
      return false;
    }
  }

  async presentToast() {

    const toast = await this.toast.create({
      //  header: 'Toast header',
      message: 'This website uses cookies to ensure you get the best experience on our website.',
      position: 'bottom',
      cssClass: 'toast-cls',
      buttons: [
        {
          text: 'Allow Cookies',
          role: 'cancel',
          cssClass: 'toast-btn',
          handler: () => {
            //('Cancel clicked');
          }
        }
      ]
    });
    toast.present();
  }

  adduserlist(list) {
    //(".............list in service page", list);
    this.listofusers = list;
  }

  userlistdetails(): Observable<any[]> {
    return of(this.listofusers);
  }
  //    userlogincheck(){
  //      let instance = this;
  //     let user_email = localStorage.getItem("user_email");
  //     let user_password = localStorage.getItem("user_password");
  //     if(user_email == null || user_password == null){
  //       //do nothing
  //     }  else{
  //       var authenticationData = {
  //         Username: user_email,
  //         Password: user_password,
  //       };
  //       var authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(authenticationData);

  //       var poolData = {
  //         UserPoolId: UserPoolId,
  //         ClientId: ClientId
  //       }
  //       var userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

  //       var userData = {
  //         Username: user_email,
  //         Pool: userPool,
  //       };
  //     var check_flag
  //     var cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
  //     console.log("authenticationDetails");
  //     console.log(authenticationDetails);
  //     cognitoUser.authenticateUser(authenticationDetails, {
  //       onSuccess: function (result) {
  //         console.log("on success");
  //         var accessToken = result.getAccessToken().getJwtToken();
  //         var auth_token = result.getIdToken().getJwtToken();
  //         localStorage.removeItem("accessToken");
  //         localStorage.removeItem("auth_token");
  //         localStorage.setItem("accessToken", accessToken);
  //         localStorage.setItem("auth_token", auth_token);

  //       },

  //       onFailure: function (err) {
  //               console.log("on fail");
  //               localStorage.clear();
  //               instance.router.navigate(['/login']);
  //         }


  //     });
  //   }
  // }
}