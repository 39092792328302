import { Component, OnInit } from '@angular/core';
import { Router, RouterEvent } from '@angular/router';
import { PopoverController, NavParams, NavController,  ModalController } from '@ionic/angular';
import { ChangePasswordComponent } from 'src/app/modals/change-password/change-password.component';
import{ AppComponent } from '../../app.component';
import { MyProfileComponent } from 'src/app/modals/myprofile/myprofile.component';
import { ApiService } from 'src/app/services/api.service';
@Component({
  selector: 'app-admin-dropdown',
  templateUrl: './admin-dropdown.component.html',
  styleUrls: ['./admin-dropdown.component.scss'],
})
export class AdminDropdownComponent implements OnInit {
from:any='';
user_type:any='';
static selected_menu;
  constructor(private navctrl:NavController, private service:ApiService, private router:Router, public popover:PopoverController, private navParams: NavParams, public modalController:ModalController) {
    
    let usercredentials = JSON.parse(localStorage.getItem("userData"));
    this.user_type = usercredentials[0].user_type;
   }

  ngOnInit() {
    this.from = this.navParams.data.page;
  }

  accounts(){
   // this.events.publish('menu:update', 'settings', Date.now());
    this.popover.dismiss();
    this.router.navigate(['/settings']);
  }

  async profile(){
    this.popover.dismiss();
    const modal = await this.modalController.create({
      component: MyProfileComponent,
      cssClass: 'profilePasswordComponent',
      backdropDismiss: false

    });
    modal.onDidDismiss()
      .then((data) => {
        
        let usercredentials = JSON.parse(localStorage.getItem("userData"));
        let email = usercredentials[0].email;
        this.verifyuser(email);
      });
    return await modal.present();
  }

  verifyuser(email){
    
    this.service.apicall('userlogin?user_email=' + email)
     .subscribe(
       resultArray => {
         
         let res:any = resultArray;
         let response = JSON.parse(res.body);
          if (response != [] && response != '') {
           let responsedata = response[0];
           if (responsedata.ID != '' && responsedata.ID != undefined) {
             
             localStorage.setItem("userData", JSON.stringify(response));
         }
         }
       });
     }

  managestores(){
   // this.events.publish('menu:update', 'managestores', Date.now());
    this.popover.dismiss();
    this.router.navigate(['/managestores']);
  }

  manageusers(){
    this.popover.dismiss();
    this.router.navigate(['/manageusers']);
  }
  dashboard(){
    this.router.navigate(['/dashboard']);
    this.popover.dismiss();
   
  }
  async change_password(){
    this.popover.dismiss();
      const modal = await this.modalController.create({
        component: ChangePasswordComponent,
        cssClass: 'ChangePasswordComponent',
        backdropDismiss: false
  
      });
      modal.onDidDismiss()
        .then((data) => {
  
        });
      return await modal.present();
    }
  
  logout(){
    localStorage.removeItem("userData");
    localStorage.removeItem("role");
    localStorage.removeItem("name");
    localStorage.removeItem('socialuser');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('auth_token');
    localStorage.removeItem("type");
    localStorage.removeItem("startDate");
    localStorage.removeItem("endDate");
    this.popover.dismiss();
    this.router.navigate(['/login']);
  }


}
